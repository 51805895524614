import { Link, useLocation, useRouteLoaderData } from "@remix-run/react";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "~/components/atoms/Icons";
import FacebookLightIcon from "~/components/atoms/Icons/FacebookLightIcon";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import SalesTeamInfoFooter from "~/components/molecules/SalesTeamInfoFooter";
import { ILocation } from "~/entities/location";
import { IMediaImageUrls } from "~/entities/media";
import { IAgencySocial, IConfig } from "~/types";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { cn } from "~/utilities/cn";
import { COMPANY_MENU, SEGMENT_MENU } from "~/utilities/config/footerConfig";
import { SOCIAL_LIGHT_ICONS } from "~/utilities/constants/socialIcons";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ESocialLinks } from "~/utilities/enums/socialLinks";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import { formatPhoneNumber } from "~/utilities/helpers/formatPhoneNumber";
import { IContentSection } from "../ContentsNavigator/types";
import { SCROLL_OFFSET } from "~/utilities/constants/projectSections";
import Button from "~/components/atoms/Button";

export interface FooterLDPProps {
  logo?: IMediaImageUrls;
  phoneNumber?: string;
  socialLinks?: IAgencySocial[];
  siteTitle?: string;
  siteDescription?: string;
  domain?: string;
  locations?: ILocation[];
  disclaimer?: string;
  wrapperClass?: string;
  className?: string;
  sections: IContentSection[];
}

export default function FooterLDP({
  logo,
  phoneNumber = "",
  socialLinks = [],
  siteTitle = "",
  siteDescription = "",
  disclaimer = "",
  domain = "",
  locations,
  wrapperClass = "",
  className = "",
  sections,
}: FooterLDPProps) {
  const { config } = useRouteLoaderData("root") as IRouteLoaderData;
  const { t } = useTranslation();
  const formattedYear = dayjs().format("YYYY");
  const location = useLocation();
  const isProjectDetailPage = /^\/projects\/[^/]+$/.test(
    location?.pathname || ""
  );

  // TODO: Remove this hardcode  when support, support remove information for  specific users
  const isHardCode = config?.userId === "dcdd283d-8a6d-4fb2-ac53-6b266d1072f3";

  const [category, setCategory] = useState<string>(t("Company"));
  const { originalDomain, project } = { ...config } as IConfig;

  useEffect(() => {
    function getTextBeforePipe(input: string): string {
      const index = input.indexOf("|");
      if (index === -1) {
        return input; // Return the whole string if '|' is not found
      }
      return input.substring(0, index).trim();
    }

    if (!isEmpty(siteTitle)) {
      const getCategory = getTextBeforePipe(siteTitle);
      setCategory(getCategory);
    }
  }, [siteTitle]);

  const scrollToView = useCallback(
    (targetId: string, offset: number = SCROLL_OFFSET) => {
      // TODO: Optimize this function
      // Make sure all element wrapper tag name is section
      let sectionEl = document.getElementById(targetId);

      if (sectionEl && sectionEl?.tagName.toLocaleLowerCase() !== "section") {
        // For group  section ids, example: landing page table units
        sectionEl = sectionEl?.closest("section");
      }

      if (sectionEl) {
        const y =
          sectionEl.getBoundingClientRect().top + window.scrollY + offset;

        // Update the URL hash without scrolling again
        window.history.pushState(null, "", `#${targetId}`);
        // if (y === window.scrollY + offset) return; // return if window not change

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    },
    []
  );

  const renderSections = useMemo(
    () =>
      !isEmpty(locations) &&
      locations
        ?.filter((location) => !isEmpty(location?.projects))
        ?.map((location, index) => (
          <div key={location?.id + index} className="flex flex-col gap-6">
            {originalDomain ? (
              <a
                key={location?.id + index}
                href={`https://${originalDomain}${Slug.PROJECTS}?location=${location?.slug}`}
                rel="noreferrer"
              >
                <Typo
                  className="font-bold capitalize text-footerText hover:underline child:!text-header-16 child:!font-bold child:!text-footerText lg:!text-header-16"
                  variant={ETypoVariant.HEADER_16}
                  color={ETypoColor.FOOTER}
                  tag={ETypoTag.DIV}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: location?.name || "" }}
                    className="ql-editor"
                  />
                </Typo>
              </a>
            ) : (
              <Link
                key={location?.id + index}
                to={`${Slug.PROJECTS}?location=${location?.slug}`}
              >
                <Typo
                  className="font-bold capitalize text-footerText hover:underline child:!text-header-16 child:!font-bold child:!text-footerText lg:!text-header-16"
                  variant={ETypoVariant.HEADER_16}
                  color={ETypoColor.FOOTER}
                  tag={ETypoTag.DIV}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: location?.name || "" }}
                    className="ql-editor"
                  />
                </Typo>
              </Link>
            )}

            {!isEmpty(location?.projects) && (
              <div className="flex flex-col gap-2">
                {location.projects?.map((project, projectIndex) =>
                  originalDomain ? (
                    <a
                      key={project?.id + projectIndex}
                      href={`https://${originalDomain}${Slug.PROJECTS}/${
                        project?.slug || ""
                      }`}
                    >
                      <Typo
                        className="!text-body-title-16 font-normal text-footerText hover:underline"
                        variant={ETypoVariant.BODY_TITLE_16}
                        color={ETypoColor.FOOTER}
                        tag={ETypoTag.P}
                      >
                        {project?.name || ""}
                      </Typo>
                    </a>
                  ) : (
                    <Link
                      key={project?.id + projectIndex}
                      to={`${Slug.PROJECTS}/${project?.slug || ""}`}
                    >
                      <Typo
                        className="!text-body-title-16 font-normal text-footerText hover:underline"
                        variant={ETypoVariant.BODY_TITLE_16}
                        color={ETypoColor.FOOTER}
                        tag={ETypoTag.P}
                      >
                        {project?.name || ""}
                      </Typo>
                    </Link>
                  )
                )}
              </div>
            )}
          </div>
        )),
    [locations]
  );

  const renderContact = useMemo(
    () => (
      <div className="col-span-1 space-y-6 lg:col-span-3">
        <Typo
          className="font-bold"
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.FOOTER}
          tag={ETypoTag.P}
        >
          {t("footer.contact")}
        </Typo>
        <div className="flex flex-col items-start justify-between gap-6 lg:flex-row lg:items-center">
          <a href={`tel:${isHardCode ? "+6561008111" : phoneNumber}`}>
            <Typo
              className="text-nowrap font-bold"
              variant={ETypoVariant.HEADER_20}
              color={ETypoColor.FOOTER}
              tag={ETypoTag.P}
            >
              {formatPhoneNumber(isHardCode ? "+6561008111" : phoneNumber)}
            </Typo>
          </a>
          <div className="flex flex-row items-center gap-6">
            {socialLinks?.map((socialLink) =>
              originalDomain ? (
                <a
                  key={socialLink?.type}
                  href={`https://${originalDomain}${socialLink?.url}` || ""}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    TypeIcon={
                      SOCIAL_LIGHT_ICONS?.[socialLink?.type as ESocialLinks] ||
                      FacebookLightIcon
                    }
                  />
                </a>
              ) : (
                <Link
                  key={socialLink?.type}
                  target="_blank"
                  to={socialLink?.url || ""}
                  rel="noreferrer"
                >
                  <Icon
                    TypeIcon={
                      SOCIAL_LIGHT_ICONS?.[socialLink?.type as ESocialLinks] ||
                      FacebookLightIcon
                    }
                  />
                </Link>
              )
            )}
          </div>
        </div>
      </div>
    ),
    [t, phoneNumber, socialLinks]
  );

  return (
    <footer
      className={cn(
        "mt-auto flex shrink-0 flex-col gap-12 overflow-x-hidden bg-footerBackground py-12",
        className
      )}
    >
      <div
        className={cn(
          "grid  grid-cols-2 gap-x-[124px] gap-y-16 lg:gap-x-[80px] md:gap-x-[40px] sm:grid-cols-1 sm:px-6",
          isProjectDetailPage && "lg:gap-y-16",
          wrapperClass
        )}
      >
        <div className="flex flex-col gap-6">
          {logo &&
            (originalDomain && project?.name ? (
              // <a href={`https://${originalDomain}${Slug.HOME}`}>
              //   <ImageResponsive
              //     imageData={logo}
              //     alt="Brand Logo"
              //     className="h-[34px] w-auto origin-left"
              //     zoom={1.3}
              //     displayDisclaimer={false}
              //   />
              // </a>
              <Typo
                tag={ETypoTag.H1}
                variant={ETypoVariant.HEADER_32}
                color={ETypoColor.FOOTER}
                className="truncate text-left font-bold"
              >
                {project.name}
              </Typo>
            ) : (
              <Link to={Slug.HOME}>
                <ImageResponsive
                  imageData={logo}
                  alt="Brand Logo"
                  className="h-[34px] w-auto max-w-full origin-left"
                  zoom={1}
                  displayDisclaimer={false}
                />
              </Link>
            ))}

          {!isHardCode && <SalesTeamInfoFooter />}
        </div>
        <div className="grid grid-cols-3 gap-6 lg:grid-cols-2 sm:gap-y-12">
          <ul className="col-span-2 grid h-fit grid-cols-2 gap-x-6 gap-y-3 lg:grid-cols-2 sm:gap-y-6">
            {sections.map((it, idx) => (
              <li key={it.label + idx}>
                <button onClick={() => scrollToView(it.targetId)}>
                  <Typo
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.BODY_TITLE_16}
                    color={ETypoColor.FOOTER}
                  >
                    {t(it.label)}
                  </Typo>
                </button>
              </li>
            ))}
          </ul>
          {renderContact}
        </div>
      </div>
      <div className={cn("flex flex-col gap-5", wrapperClass)}>
        {disclaimer && (
          <div
            dangerouslySetInnerHTML={{ __html: disclaimer }}
            className={cn("ql-editor text-footerText")}
          />
        )}
        {siteDescription && (
          <div
            dangerouslySetInnerHTML={{ __html: siteDescription }}
            className={cn("ql-editor text-footerText")}
          />
        )}
      </div>
      {isProjectDetailPage && (
        <div className={wrapperClass}>
          <div className="grid grid-cols-4 gap-12 border-t border-t-footerBorder pt-12 lg:grid-cols-2 lg:gap-x-4">
            {renderSections}
          </div>
        </div>
      )}
      <div
        className={cn(
          "flex flex-row items-center gap-12 sm:flex-col sm:items-start sm:gap-2 sm:px-6",
          wrapperClass
        )}
      >
        {originalDomain ? (
          <a href={`https://${originalDomain}${Slug.PRIVACY_POLICY}`}>
            <Typo
              className="underline"
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_16}
              color={ETypoColor.FOOTER}
            >
              {t("footer.privacy_policy")}
            </Typo>
          </a>
        ) : (
          <Link to="/privacy-policy">
            <Typo
              className="underline"
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_16}
              color={ETypoColor.FOOTER}
            >
              {t("footer.privacy_policy")}
            </Typo>
          </Link>
        )}

        <Typo
          className="opacity-50"
          tag={ETypoTag.SPAN}
          variant={ETypoVariant.BODY_TITLE_16}
          color={ETypoColor.FOOTER}
        >
          {t("footer.copyright")} © {formattedYear} {domain}
        </Typo>
      </div>
    </footer>
  );
}
