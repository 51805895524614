import {
  ActionFunctionArgs,
  json,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from "@remix-run/node";
import {
  useFetcher,
  useLoaderData,
  useNavigation,
  useParams,
  useRouteLoaderData,
} from "@remix-run/react";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from "react";
import { getFormDataFromSearchParams, parseFormData } from "remix-hook-form";

import { AutocompleteOption } from "~/components/molecules/SearchBar";
import HighlightCardList from "~/components/organisms/HighlightCardList/HighlightCardList";
import Masthead from "~/components/organisms/Masthead";
import MastheadMobile from "~/components/organisms/MastheadMobile";
import Sections from "~/components/sections";
import SectionProjectTopWithGallery from "~/components/sections/SectionProjectTopWithGallery";
import SectionsLDP from "~/components/templates/SectionsLDP";
import { IFloorPlan, IFloorPlanFilterOption } from "~/entities/floorPlan";
import { IProject } from "~/entities/project";
import { IPromotionBanner } from "~/entities/promotion";
import { ISection } from "~/entities/section";
import { IIpTrackingDTO } from "~/entities/tracking";
import {
  ISaleTransactionFilterOption,
  IUnitTransaction,
} from "~/entities/unitTransaction";
import { getAutocompleteProject } from "~/services/autocompleteService";
import { getConfigByDomain } from "~/services/configService";
import {
  submitContactForm,
  TContactFormReq,
} from "~/services/contactSaleService";
import { getLandingPageConfigByDomain } from "~/services/landingPageService";
import {
  getFloorPlanOptionsByProject,
  getFloorPlans,
  getPaginatedFloorPlansByProjectAndUnitTypes,
  getProjects,
  getPromotionBannersBySection,
  getSaleTransactionOptionsByProject,
  getSections,
  getUnitTransactions,
} from "~/services/projectService";
import { trackingUserByProject } from "~/services/trackingService";
import { commitSession, getSession } from "~/session";
import useAppState from "~/stores";
import {
  IPaginateResponseData,
  IPaginationReq,
  ITimelineEvent,
  ITrackingTimeline,
} from "~/types";
import { IConfig } from "~/types/config";
import { THomeLoaderData } from "~/types/homeLoaderData";
import { TProjectLoaderData } from "~/types/projectLoaderData";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { IUser } from "~/types/user";
import {
  COUNTRY_PHONE_NUMBER_DIALCODE,
  FORMAT_FULLDAY,
  LIMIT,
  OPTIONS_CONTACT_SALE_INTERESTED,
  PAGE,
} from "~/utilities/constants/common";
import { HIGHLIGHT_NAVIGATOR } from "~/utilities/constants/highlightNavigator";
import { projectSections } from "~/utilities/constants/projectSections";
import { REQUEST_KEYS, REQUEST_NAME } from "~/utilities/constants/requestKey";
import { EApiParam } from "~/utilities/enums/ApiParam";
import { ELayout } from "~/utilities/enums/Layouts";
import { Slug } from "~/utilities/enums/Slug";
import { getClientInformation } from "~/utilities/helpers/getClientInformation";
import isValidJson from "~/utilities/helpers/isValidJson";
import { getMetadata } from "~/utilities/helpers/metadata";
import { parseSearchParams } from "~/utilities/helpers/queryString";
import { extractDomainFromRequest } from "~/utilities/helpers/requestHelper";
import { TContactSaleFormData } from "~/utilities/schema/contact-sale";

const LOADER_ACTIONS = {
  TRACKING_USER: "trackingUserData",
};

export const meta: MetaFunction<typeof loader> = (args) => {
  const { data, matches } = args;

  const rootMatch = matches?.find((match) => match?.id === "root");

  if (!rootMatch) {
    return [];
  }

  const { metadata } = rootMatch.data as IRouteLoaderData;

  if (!data) {
    return [];
  }

  const robots = "index,follow";

  return getMetadata({
    title: metadata?.title,
    description: metadata?.description,
    image: metadata?.ogImage,
    twitterCreator: metadata?.twitterCreator,
    twitterSite: metadata?.twitterSite,
    additionalMeta: [
      { name: "og:type", content: "website" },
      {
        name: "og:site_name",
        content: metadata?.title,
      },
      { name: "robots", content: robots },
      { name: "googlebot", content: robots },
    ],
  });
};

export async function loader({ params, request }: LoaderFunctionArgs) {
  const requestUrl = new URL(request.url);
  let domain = extractDomainFromRequest(request);
  const controller = new AbortController();
  const signal = controller.signal;

  let config = {} as IConfig;

  config = (await getLandingPageConfigByDomain(domain)) || ({} as IConfig);

  const project = config?.project || ({} as IProject);
  if (config?.originalDomain) {
    domain = config?.originalDomain;
  }

  if (isEmpty(config)) {
    config = (await getConfigByDomain(domain)) || ({} as IConfig);
  }

  const siteUrl = requestUrl.protocol + "//" + requestUrl.host;

  const searchParams = getFormDataFromSearchParams(request) as IPaginationReq;

  const queryParsed = parseSearchParams(requestUrl.searchParams);
  let sections = {} as IPaginateResponseData<ISection>;

  let featuredProjects = {} as IPaginateResponseData<IProject>;
  let sectionFeaturedLaunches = {} as ISection;
  let promotionBanners: IPromotionBanner[] = [];
  let optionsAutocomplete;
  if (isEmpty(project)) {
    featuredProjects = await getProjects({
      domain,
      searchParams: {
        featured: true,
        page: Number(PAGE),
        limit: Number(LIMIT),
      },
    });
    sectionFeaturedLaunches = {
      id: "featured-launches",
      name: "Featured Launches",
      slug: Slug.FEATURED_LAUNCHES,
      background: "transparent",
      projects: featuredProjects?.items || [],
      layout: ELayout.SECTION_PROJECT_TOP_WITH_GALLERY,
    };
    if (searchParams?.sectionId) {
      const promotionBannersResponse: IPaginateResponseData<IPromotionBanner> =
        await getPromotionBannersBySection(
          searchParams?.sectionId || "",
          domain
        );
      promotionBanners = promotionBannersResponse?.items || [];
    }
    if (
      queryParsed?.[REQUEST_KEYS.TRIGGER] === REQUEST_NAME.AUTOCOMPLETE_OPTIONS
    ) {
      const response = await getAutocompleteProject({
        domain,
        searchParams: {
          page: queryParsed?.autocompleteParams?.page,
          limit: queryParsed?.autocompleteParams?.limit,
          name: queryParsed?.autocompleteParams?.search,
        },
      });
      const convertedDataAutocomplete: AutocompleteOption[] =
        response?.items?.map((item) => ({
          ...item,
          label: item.projectName,
          value: item.projectSlug,
        }));
      optionsAutocomplete = {
        ...response,
        items: convertedDataAutocomplete,
      };
    }
    sections = await getSections({
      domain,
      pagination: {
        page: searchParams?.page || PAGE,
        limit: searchParams?.limit || LIMIT,
      },
    });
  }

  const clientInformation = getClientInformation(request);

  const url = new URL(request.url);

  const tz = url.searchParams.get("tz");
  const triggerAction = url.searchParams.get("trigger");

  if (
    project?.id &&
    clientInformation?.ip &&
    !!tz &&
    triggerAction === LOADER_ACTIONS.TRACKING_USER
  ) {
    const [region] = tz.split("/");
    const trackingUserData: IIpTrackingDTO = {
      ip: clientInformation.ip || "Unknown",
      region: region,
      projectId: project.id,
      projectLink: siteUrl,
      projectName: project.name,
      browser: clientInformation.browser,
      country: clientInformation?.country,
      countryCode: clientInformation?.countryCode,
    };
    await trackingUserByProject(trackingUserData, domain);
  }

  let saleTransactionFilterOptions = [] as ISaleTransactionFilterOption[];
  let floorPlanFilterOptions = [] as IFloorPlanFilterOption[];

  let floorPlansPagination = {} as IPaginateResponseData<IFloorPlan>;
  const floorPlansModalPagination = {} as IPaginateResponseData<IFloorPlan>;

  let unitTransactions = {} as IPaginateResponseData<IUnitTransaction>;
  const unitTypeIds = url.searchParams.get(EApiParam.UNITTYPEIDS);
  const page = url.searchParams.get(EApiParam.PAGE) || PAGE;
  const limit = url.searchParams.get(EApiParam.LIMIT) || LIMIT;

  const pricePage = url.searchParams.get(EApiParam.PRICE_PAGE) || PAGE;
  const priceLimit = url.searchParams.get(EApiParam.PRICE_LIMIT) || LIMIT;

  const fromDate =
    url.searchParams.get(EApiParam.START_DATE) ??
    (saleTransactionFilterOptions?.[0]?.fromDate || "");

  const toDate =
    url.searchParams.get(EApiParam.END_DATE) ??
    (saleTransactionFilterOptions?.[0]?.toDate || "");

  if (!isEmpty(project)) {
    saleTransactionFilterOptions = await getSaleTransactionOptionsByProject(
      domain,
      project.slug as string
    );

    floorPlanFilterOptions = await getFloorPlanOptionsByProject(
      domain,
      project.slug as string
    );

    floorPlansPagination = unitTypeIds
      ? await getPaginatedFloorPlansByProjectAndUnitTypes(
          domain,
          project.slug as string,
          {
            [EApiParam.UNITTYPEIDS]: url.searchParams.get(
              EApiParam.UNITTYPEIDS
            ) as string,
            [EApiParam.PAGE]: `${page}`,
            [EApiParam.LIMIT]: `${limit}`,
          }
        )
      : await getFloorPlans(domain, project.slug as string, {
          page: Number(page),
          limit: Number(limit),
        });

    unitTransactions = await getUnitTransactions(domain, {
      [EApiParam.PROJECT_SLUG]: project?.slug || "",
      [EApiParam.START_DATE]: fromDate,
      [EApiParam.END_DATE]: toDate,
      [EApiParam.PAGE]: `${page}`,
      [EApiParam.LIMIT]: `${limit}`,
      [EApiParam.SORT_BY]: `timestamp`,
      [EApiParam.SORT]: `DESC`,
    });
  }

  const projectMenuItems = [...projectSections];

  const virtualTours = project?.virtualTours || [];

  // get all filter api option
  // const resUnitTypes = await getUnitTypes(domain);

  const loaderData: THomeLoaderData & TProjectLoaderData = {
    siteUrl,
    config,
    sections,
    promotionBanners,
    featuredProjects,
    optionsAutocomplete,
    sectionFeaturedLaunches,
    projectMenuItems,
    project,
    floorPlansPagination,
    unitTransactions,
    virtualTours,
    floorPlansModalPagination,
    // filterOptions: {
    //   unitTypes: resUnitTypes?.map((unit) => ({
    //     id: unit.slug,
    //     name: unit.title,
    //   })),
    // },
    saleTransactionFilterOptions: Array.isArray(saleTransactionFilterOptions)
      ? saleTransactionFilterOptions
      : [],
    floorPlanFilterOptions: Array.isArray(floorPlanFilterOptions)
      ? floorPlanFilterOptions?.sort((a, b) => a.title.localeCompare(b.title))
      : [],
  };

  return json(loaderData);
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await parseFormData<Record<string, any>>(request, false);
  const url = new URL(request.url);
  const domain = extractDomainFromRequest(request);
  const config =
    (await getLandingPageConfigByDomain(domain)) || ({} as IConfig);

  // TODO: Optimze this to save original domain when loader
  const originDomain = config?.originalDomain;

  const isLandingPage = !!originDomain;
  // Is landing page

  if (isLandingPage) {
    let trackingTimeline =
      formData?.tracking &&
      isValidJson(formData?.tracking) &&
      (JSON.parse(formData?.tracking) as ITrackingTimeline);

    trackingTimeline = trackingTimeline
      ? { ...trackingTimeline, endTime: new Date() }
      : undefined;

    const tempData: TContactFormReq = {
      projectSlug: formData?.projectSlug || "",
      interestedIn:
        JSON.stringify(
          formData?.interested?.map((i: any) => {
            const findValues = OPTIONS_CONTACT_SALE_INTERESTED.find(
              (val) => val.id === i
            )?.name;
            return findValues || i;
          })
        ) || "",
      phone: `${COUNTRY_PHONE_NUMBER_DIALCODE}${formData?.phone}`,
      name: formData?.name,
      email: formData?.email,
      appointment: dayjs(
        `${formData?.appointmentDate} ${formData?.appointmentTime}`
      ).format(FORMAT_FULLDAY),
      unitTypes: JSON.stringify(formData?.unitTypes) || "",
      tracking: JSON.stringify(trackingTimeline) || "",
    };
    const clientInformation = getClientInformation(request);

    const response = await submitContactForm(
      {
        data: tempData,
        userAgent: clientInformation?.userAgent || null,
        ip: clientInformation?.ip || null,
      },
      originDomain
    );

    console.log(response);
    if (response) {
      const session = await getSession(request.headers.get("Cookie"));
      session.flash("message", "enquiryReceived");
      const cookie = await commitSession(session);
      return redirect(`${Slug.ENQUIRY_RECEIVED}`, {
        headers: {
          "Set-Cookie": cookie,
        },
      });
    }
    return null;
  }

  //  Search project
  Object.keys(formData)?.forEach((key) => {
    if (
      formData[key] !== "" &&
      formData[key] !== undefined &&
      formData[key] !== null &&
      formData[key] !== "null"
    ) {
      return url.searchParams.set(key, formData[key]);
    }
    return url.searchParams.delete(key);
  });

  const params = url.searchParams.toString();
  return redirect(`${Slug.PROJECTS}?${params}`);
}

export default function Index() {
  const {
    sections: serverSections,
    sectionFeaturedLaunches,
    projectMenuItems,
    project,
  } = useLoaderData() as THomeLoaderData & TProjectLoaderData;

  const { config, userConfig } = useRouteLoaderData("root") as IRouteLoaderData;

  let user: IUser = useDeferredValue(userConfig);

  const fetcher = useFetcher<THomeLoaderData>();
  const [sections, setSections] = useState<ISection[]>(serverSections?.items);
  const [currentPage, setCurrentPage] = useState<number>(
    serverSections?.currentPage
  );

  const onFetchMoreSections = useCallback(() => {
    if (
      fetcher.state === "loading" ||
      currentPage === fetcher?.data?.sections?.totalPage
    ) {
      return;
    }

    const newPage = currentPage + 1;
    if (newPage > serverSections?.totalPage) return;
    fetcher.load(`/?page=${newPage}`);

    setCurrentPage(newPage);
  }, [currentPage, fetcher, serverSections?.totalPage]);

  useEffect(() => {
    if (
      !fetcher.data ||
      fetcher.state === "loading" ||
      currentPage === fetcher?.data?.sections?.totalPage
    ) {
      return;
    }

    if (fetcher.data) {
      const newItems = (fetcher?.data?.sections?.items || []) as ISection[];
      setSections((prevSections) => [...prevSections, ...newItems]);
    }
  }, [fetcher.data, currentPage, fetcher.state]);

  const navigation = useNavigation();

  const { addTrackingData } = useAppState((state) => state);

  const params = useParams<{ slug: string }>();
  const currentSlug = params?.slug;

  const sectionRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    sectionRefs.current = sectionRefs.current.slice(0, projectMenuItems.length);
  }, [projectMenuItems.length]);

  if (!user?.photo && !!config?.agencyPhoto)
    user = { ...user, photo: config.agencyPhoto };
  if (!user?.phone && !!config?.phoneNumber)
    user = { ...user, phone: config.phoneNumber };

  useEffect(() => {
    if (project) {
      const timelineEvent: ITimelineEvent = {
        visitedAt: new Date(),
        projectId: project.id,
        projectName: project.name,
      };

      if (navigation?.state === "submitting") {
        timelineEvent.submittedAt = new Date();
      }

      addTrackingData(timelineEvent, navigation?.state === "submitting");
    }
  }, [addTrackingData, navigation?.state, project]);

  // const floorPlanItems: IFloorPlanItem[] = useMemo(() => {
  //   return (
  //     (
  //       fetcher?.data?.floorPlansModalPagination?.items ||
  //       floorPlansModalPagination?.items ||
  //       []
  //     )?.map((item) => ({
  //       type: item?.unitType?.title || "",
  //       floorPlanName: item?.name || "",
  //       totalUnit: item?.totalUnits || 0,
  //       sold: (item?.totalUnits || 0) - item?.availableUnits || 0,
  //       available: item?.availableUnits || 0,
  //       size: item?.area || 0,
  //       price:
  //         (item?.availableUnits || 0) === 0
  //           ? t("sold_out")
  //           : `${formatCurrency(item?.minPrice || 0)} - ${formatCurrency(
  //               item?.maxPrice || 0
  //             )}`,
  //       psf:
  //         (item?.availableUnits || 0) === 0
  //           ? t("sold_out")
  //           : `${formatCurrency(item?.minPSF || 0)} - ${formatCurrency(
  //               item?.maxPSF || 0
  //             )}`,
  //     })) || []
  //   );
  // }, [
  //   fetcher?.data?.floorPlansModalPagination?.items,
  //   floorPlansModalPagination?.items,
  //   t,
  // ]);

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const query = `?trigger=${LOADER_ACTIONS.TRACKING_USER}&tz=${timezone}`;
    fetcher.load(query);
  }, [currentSlug]);

  return (
    <>
      {isEmpty(project) ? (
        <main className="overflow-hidden">
          {config && <Masthead image={config.coverImage} />}
          {config && <MastheadMobile image={config.mobileMastheadImage} />}
          <HighlightCardList cards={HIGHLIGHT_NAVIGATOR} />

          {sectionFeaturedLaunches && (
            <SectionProjectTopWithGallery data={sectionFeaturedLaunches} />
          )}
          <Sections
            sections={sections}
            infinitiLoad
            isLoading={fetcher.state === "loading"}
            onLoadMore={onFetchMoreSections}
          />
        </main>
      ) : (
        <SectionsLDP />
      )}
    </>
  );
}
