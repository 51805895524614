import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";

import SectionProjectAvailableUnits from "../SectionProjectAvailableUnits";
import SectionProjectFloorPlans from "../SectionProjectFloorPlans";
import SectionProjectSaleTransaction from "../SectionProjectSaleTransaction";

import Button from "~/components/atoms/Button";
import Typo from "~/components/atoms/Typo";
import SectionProjectUnitMix from "~/components/sections/SectionProjectUnitMix";
import { IFloorPlan, IFloorPlanFilterOption } from "~/entities/floorPlan";
import { IProject } from "~/entities/project";
import {
  ISaleTransactionFilterOption,
  IUnitTransaction,
} from "~/entities/unitTransaction";
import { IPaginateResponseData } from "~/types";
import { cn } from "~/utilities/cn";
import projectSectionConfig from "~/utilities/config/projectSectionConfig";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Section } from "~/utilities/enums/ProjectSections";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import { useMemo } from "react";
import { useIsClient } from "~/hooks/use-is-client";

interface SectionUnitsLDPProps {
  className?: string;
  project: IProject;
  floorPlansPagination: IPaginateResponseData<IFloorPlan>;
  floorPlanFilterOptions: IFloorPlanFilterOption[];
  unitTransactions: IPaginateResponseData<IUnitTransaction>;
  saleTransactionFilterOptions: ISaleTransactionFilterOption[];
}

const SectionUnitsLDP: React.FC<SectionUnitsLDPProps> = ({
  project,
  floorPlansPagination,
  floorPlanFilterOptions,
  unitTransactions,
  saleTransactionFilterOptions,
  className = "",
}) => {
  const { t } = useTranslation();

  const tabsList: any[] = useMemo(() => {
    return [
      {
        TabPanel: (
          <SectionProjectUnitMix
            id=""
            title={projectSectionConfig[Section.UNIT_MIX].title}
            description={projectSectionConfig[Section.UNIT_MIX].description}
            project={project}
            className="lg:gap-6 lg:py-1.5"
          />
        ),
        hidden: isEmpty(project?.unitTypes),
        title: t(projectSectionConfig[Section.UNIT_MIX].title),
        targetId: Section.UNIT_MIX,
      },
      {
        TabPanel: (
          <SectionProjectAvailableUnits
            id=""
            title={projectSectionConfig[Section.AVAILABLE_UNITS].title}
            description={
              projectSectionConfig[Section.AVAILABLE_UNITS].description
            }
            project={project}
          />
        ),
        hidden: isEmpty(
          project.unitTypes.filter((item) => !!item.availableUnits)
        ),
        title: t(projectSectionConfig[Section.AVAILABLE_UNITS].title),
        targetId: Section.AVAILABLE_UNITS,
      },
      {
        TabPanel: (
          <SectionProjectFloorPlans
            id=""
            title={projectSectionConfig[Section.FLOOR_PLANS].title}
            description={projectSectionConfig[Section.FLOOR_PLANS].description}
            floorPlansPagination={floorPlansPagination}
            project={project}
            unitTypeFilterOptions={floorPlanFilterOptions}
          />
        ),
        hidden: isEmpty(floorPlansPagination.items),
        title: t(projectSectionConfig[Section.FLOOR_PLANS].title),
        targetId: Section.FLOOR_PLANS,
      },
      {
        TabPanel: (
          <SectionProjectSaleTransaction
            id=""
            title={projectSectionConfig[Section.SALES_TRANSACTION].title}
            description={
              projectSectionConfig[Section.SALES_TRANSACTION].description
            }
            unitTransactions={unitTransactions}
            saleTransactionFilterOptions={saleTransactionFilterOptions}
          />
        ),
        hidden: isEmpty(unitTransactions.items),
        title: t(projectSectionConfig[Section.SALES_TRANSACTION].title),
        targetId: Section.SALES_TRANSACTION,
      },
    ].filter((item) => !item.hidden);
  }, [
    project,
    floorPlanFilterOptions,
    floorPlansPagination,
    unitTransactions,
    saleTransactionFilterOptions,
    t,
  ]);

  const isHideSection = tabsList.every((item) => item.hidden);

  if (isHideSection) return <></>;
  const defaultActive = tabsList.findIndex((item) => !item.hidden);

  return (
    <section className="w-full overflow-hidden bg-background py-20 lg:py-12">
      <TabGroup
        defaultIndex={defaultActive}
        vertical
        className={cn(
          "grid grid-cols-4 lg:grid-cols-1 lg:gap-12",
          className,
          "lg:px-0"
        )}
      >
        <TabList className=" col-span-1 flex flex-col items-start justify-start gap-6 lg:flex-row lg:gap-3 lg:overflow-x-auto lg:px-4">
          {tabsList.map((item) => (
            <Tab hidden={item.hidden} className="outline-none ring-0">
              {({ selected }) => (
                <Button
                  variant={selected ? "default" : "outline"}
                  className="rounded-full"
                >
                  <Typo
                    className="whitespace-nowrap font-normal capitalize"
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.SUB_TITLE_20}
                    color={selected ? ETypoColor.WHITE : ETypoColor.TEXT}
                  >
                    {item.title}
                  </Typo>
                </Button>
              )}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="col-span-3 bg-backgroundPageProject px-6 lg:col-span-1 lg:px-0">
          {tabsList.map((tab) => (
            <TabPanel id={tab.targetId} hidden={tab.hidden}>
              {tab.TabPanel}
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </section>
  );
};

export default SectionUnitsLDP;
