import { useLocation, useMatches } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";

import { IProject } from "~/entities/project";
import { Slug } from "~/utilities/enums/Slug";

export const usePageChecker = (landingPageProject?: IProject) => {
  const location = useLocation();
  const matches = useMatches();

  const isHomePage =
    location.pathname === Slug.HOME && isEmpty(landingPageProject);

  const isLandingPage = location.pathname === Slug.HOME && landingPageProject;

  const isProjectPage = matches.some(
    (match) =>
      match.pathname.startsWith(`${Slug.PROJECTS}`) &&
      match.pathname !== Slug.PROJECTS
  );

  const isSectionsPage = matches.some(
    (match) =>
      match.pathname.startsWith(`${Slug.SECTIONS}`) &&
      match.pathname !== Slug.SECTIONS
  );

  const isLayoutsPage = matches.some(
    (match) =>
      match.pathname.startsWith(`${Slug.LAYOUT}`) &&
      match.pathname !== Slug.LAYOUT
  );

  const isComparisonPage = matches.some((match) =>
    match.pathname.startsWith(`${Slug.COMPARE}`)
  );

  const isEnquiryReceivedPage = matches.some((match) =>
    match.pathname.startsWith(`${Slug.ENQUIRY_RECEIVED}`)
  );

  return {
    isComparisonPage,
    isHomePage,
    isLandingPage,
    isLayoutsPage,
    isProjectPage,
    isSectionsPage,
    isEnquiryReceivedPage,
  };
};
