import isEmpty from "lodash/isEmpty";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import CleanedContent from "~/components/atoms/CleanedContent";
import Typo from "~/components/atoms/Typo";
import MastheadGalleryLDP from "~/components/organisms/MastheadGalleryLDP";
import SectionProject from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";
import { useIsClient } from "~/hooks/use-is-client";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SectionProjectSitePlanProps {
  className?: string;
  id: string;
  title: string;
  description: string;
  project: IProject;
}

export type Ref = HTMLDivElement;

const SectionProjectSitePlanLDP = forwardRef<Ref, SectionProjectSitePlanProps>(
  ({ className = "", id, title, description, project }, ref) => {
    const { t } = useTranslation();

    const isClient = useIsClient();

    const mastheadRef = useRef<HTMLDivElement>(null);
    const [masheadHeight, setMastheadHeight] = useState<number>(0);
    const isMobile = useMediaQuery({ query: "(max-width: 992px)" });

    useEffect(() => {
      if (!isClient) return;
      const updateHeight = () => {
        if (mastheadRef?.current) {
          setMastheadHeight(mastheadRef.current.clientHeight);
        }
      };
      if (mastheadRef?.current) {
        setMastheadHeight(mastheadRef.current.clientHeight);
      }

      window.addEventListener("resize", updateHeight);

      return () => {
        window.removeEventListener("resize", updateHeight);
      };
    }, [isClient]);

    if (!project) return null;

    return (
      <SectionProject
        ref={ref}
        id={id}
        className={cn(
          "grid-auto-rows-min grid grid-cols-3 gap-6 py-20 lg:grid-cols-1 lg:py-12",
          className
        )}
      >
        {project?.medias.length > 0 && (
          <div
            className="order-1 col-span-2 bg-backgroundImage lg:order-2 lg:col-span-1"
            ref={mastheadRef}
          >
            <MastheadGalleryLDP
              project={project}
              onShowPriceList={() => {}}
              onShowFlat={() => {}}
              isShowColtrols={false}
              isShowProjectName={false}
              size="small"
              showGradientLayer={false}
            />
          </div>
        )}
        <div
          className="order-2 col-span-1 flex flex-col gap-12 pl-12 lg:order-1 lg:px-0"
          style={{ height: isMobile ? "auto " : masheadHeight }}
        >
          <div className="flex flex-col gap-2 lg:gap-1">
            <Typo
              tag={ETypoTag.H3}
              variant={ETypoVariant.HEADER_PROJECT_24}
              color={ETypoColor.PROJECT_HEADING}
              className="font-bold"
            >
              {t(title)}
            </Typo>
            {description && (
              <Typo variant={ETypoVariant.SUB_TITLE_16} tag={ETypoTag.P}>
                {t(description)}
              </Typo>
            )}
          </div>
          {!isEmpty(project?.facilityHtml) && (
            <CleanedContent
              htmlContent={project?.facilityHtml || ""}
              className="scroll order-3 h-full max-h-[480px] overflow-auto text-body-title-16 text-color lg:hidden"
            />
          )}
        </div>
        {!isEmpty(project?.facilityHtml) && (
          <CleanedContent
            htmlContent={project?.facilityHtml || ""}
            className="scroll order-3 hidden h-fit max-h-[480px] overflow-auto text-body-title-16 text-color lg:mx-0 lg:block"
          />
        )}
      </SectionProject>
    );
  }
);

SectionProjectSitePlanLDP.displayName = "SectionProjectSitePlanLDP";

export default SectionProjectSitePlanLDP;
