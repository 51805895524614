import * as zod from "zod";

import { REGEX_PHONE } from "./regex";

export const contactSalesSchema = zod.object({
  interested: zod.array(zod.string()).optional(),
  name: zod.coerce.string(),
  projectSlug: zod.coerce.string().optional(),
  email: zod.string().email().min(1, { message: "Email is required" }),
  phone: zod
    .string()
    .min(1, { message: "Phone number is required" })
    .regex(
      REGEX_PHONE.SG_PHONE_WITHOUT_DIALCODE,
      "Invalid phone number format"
    ),
  appointmentTime: zod.coerce.string(),
  appointmentDate: zod.coerce.string(),
  unitTypes: zod.array(zod.string()).optional(),
  tracking: zod.coerce.string().optional(),
  formNotice: zod.array(zod.string()).optional(),

});

export type TContactSaleFormData = zod.infer<typeof contactSalesSchema>;
