import { Link, useLocation, useMatches, useNavigate } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ContactUsButton from "../ContactUsButton";
import { IContentSection } from "../ContentsNavigator/types";

import { CustomDrawer } from "~/components/atoms/CustomDrawer";
import { Icon } from "~/components/atoms/Icons";
import FacebookDarkIcon from "~/components/atoms/Icons/FacebookDarkIcon";
import IconMenuBar from "~/components/atoms/Icons/IconMenuBar";
import Typo from "~/components/atoms/Typo";
import { IMediaImageUrls } from "~/entities/media";
import { IProject } from "~/entities/project";
import useScrollTopThreshold from "~/hooks/use-scroll-top-threshold";
import { IAgencySocial, ILink } from "~/types";
import { cn } from "~/utilities/cn";
import { SCROLL_OFFSET } from "~/utilities/constants/projectSections";
import { SOCIAL_DARK_ICONS } from "~/utilities/constants/socialIcons";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ESocialLinks } from "~/utilities/enums/socialLinks";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface HeaderMobileProps {
  logo?: IMediaImageUrls;
  projectMenuItems: IContentSection[];
  phoneNumber?: string;
  socialLinks?: IAgencySocial[];
  topButton: ILink;
  top?: number;
  isSticky?: boolean;
  project?: IProject;
  className?: string;
}

export type Ref = HTMLDivElement;

export type CustomDrawerRef = { onClose: () => void };

// eslint-disable-next-line react/display-name
const HeaderMobileLDP = forwardRef<Ref, HeaderMobileProps>(
  (
    {
      logo,
      phoneNumber = "",
      socialLinks,
      project,
      projectMenuItems,
      topButton,
      isSticky = false,
      className = "",
    },
    ref
  ) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const buttonRef = useRef<HTMLDivElement | null>(null);
    const drawerIconRef = useRef<HTMLDivElement | null>(null);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [restSpace, setRestSpace] = useState<number>(0);
    const SPACING = 24;
    const PADDING = 52;
    const matches = useMatches();
    const scrollTop = useScrollTopThreshold();
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [currentSection, setCurrentSection] = useState<string | null>(null);
    const [projectMenu, setProjectMenu] = useState<IContentSection[]>();

    const drawerRef = useRef<CustomDrawerRef>(null);

    const isHomePage = matches.some((match) => match.pathname === Slug.HOME);

    // State to keep track of last scroll position
    useEffect(() => {
      if (scrollTop < lastScrollTop) {
        setOpenDrawer(false);
      }
      setLastScrollTop(scrollTop);
    }, [scrollTop, lastScrollTop]);

    // useEffect(() => {
    //   if (buttonRef?.current && drawerIconRef?.current) {
    //     setRestSpace(
    //       buttonRef?.current?.offsetWidth +
    //         drawerIconRef?.current?.offsetWidth +
    //         SPACING +
    //         PADDING
    //     );
    //   }

    // const handleClickOutside = (event: MouseEvent) => {
    //   if (
    //     drawerRef.current &&
    //     !drawerRef?.current?.contains(event.target as Node) &&
    //     !drawerIconRef.current?.contains(event.target as Node)
    //   ) {
    //     setOpenDrawer(false);
    //   }
    // };

    //   const handleKeyDown = (event: KeyboardEvent) => {
    //     if (event.key === "Escape") {
    //       setOpenDrawer(false);
    //     }
    //   };

    //   if (openDrawer) {
    //     document.addEventListener("mousedown", handleClickOutside);
    //     document.addEventListener("keydown", handleKeyDown);
    //   } else {
    //     document.removeEventListener("mousedown", handleClickOutside);
    //     document.removeEventListener("keydown", handleKeyDown);
    //   }

    //   return () => {
    //     document.removeEventListener("mousedown", handleClickOutside);
    //     document.removeEventListener("keydown", handleKeyDown);
    //   };
    // }, [openDrawer]);

    const scrollToView = (
      targetId: string,
      offset: number | undefined = SCROLL_OFFSET
    ) => {
      setOpenDrawer(false);
      // Make sure all element wrapper tag name is section
      let sectionEl = document.getElementById(targetId);

      if (sectionEl && sectionEl?.tagName.toLocaleLowerCase() !== "section") {
        // For group  section ids, example: landing page table units
        sectionEl = sectionEl?.closest("section");
      }

      if (sectionEl) {
        const y =
          sectionEl.getBoundingClientRect().top + window.scrollY + offset;

        // Update the URL hash without scrolling again
        window.history.pushState(null, "", `#${targetId}`);
        // if (y === window.scrollY + offset) return; // return if window not change

        window.scrollTo({ top: y, behavior: "smooth" });
      }

      setCurrentSection(targetId);
    };

    useEffect(() => {
      if (projectMenuItems[0]?.targetId) {
        setProjectMenu(projectMenuItems);
        setCurrentSection(projectMenuItems[0].targetId);
      }
    }, [projectMenuItems]);

    const validProjectDataSlug = useMemo(() => {
      return isHomePage && !isEmpty(project?.name) && !isEmpty(project?.slug);
    }, [isHomePage, project?.name, project?.slug]);

    const scrollTo = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return (
      <>
        <header
          ref={ref}
          className={cn(
            `sticky top-0 z-100 hidden flex-col gap-3 overflow-hidden bg-backgroundPageProject py-3 transition-all duration-400 lg:flex`,
            isSticky && "w-full border-b shadow-header",
            className
          )}
        >
          <div className="gap-1/2 container flex flex-nowrap  items-center justify-between px-4 lg:gap-x-5">
            {/* <Icon
              ref={drawerIconRef}
              TypeIcon={OpenMenuIcon}
              onClick={() => setOpenDrawer(!openDrawer)}
              className="cursor-pointer"
            /> */}

            {validProjectDataSlug && project?.name && (
              // <Link to={Slug.HOME} className="shrink-0">
              //   <ImageResponsive
              //     imageData={logo}
              //     alt="Brand Logo"
              //     className="h-[25.2px] w-auto origin-left "
              //     zoom={1.3}
              //     displayDisclaimer={false}
              //   />
              // </Link>
              <Typo
                tag={ETypoTag.H1}
                variant={ETypoVariant.HEADER_32}
                color={ETypoColor.HEADER_TEXT}
                className="truncate text-center font-bold"
              >
                {project?.name}
              </Typo>
            )}
            <div className="flex flex-row gap-4">
              <div ref={buttonRef}>
                <ContactUsButton
                  label={topButton?.label || t("contact_us")}
                  url={topButton?.url}
                />
              </div>

              <CustomDrawer
                ref={drawerRef}
                containerClass="w-5 h-5"
                wrapperTitleClass=""
                closeIconClass="mr-0"
                actionDividerClass="hidden"
                side={
                  <div className="flex-1">
                    {!isEmpty(projectMenu) &&
                      projectMenu?.map((section) => {
                        const active = currentSection === section.targetId;
                        return (
                          <div
                            key={section.targetId}
                            onClick={() => {
                              drawerRef.current?.onClose();

                              scrollToView(section.targetId, SCROLL_OFFSET);
                            }}
                            aria-hidden
                            className="flex items-center justify-start border-b border-headerDivider py-5 last-of-type:border-none"
                          >
                            <Typo
                              tag={ETypoTag.H3}
                              variant={ETypoVariant.BODY_TITLE_16}
                              color={ETypoColor.HEADER_TEXT}
                              className={cn(
                                "text-center transition-all duration-200 hover:text-headerTextHover",
                                active ? "font-bold text-headerTextActive" : ""
                              )}
                            >
                              {t(section.label)}
                            </Typo>
                          </div>
                        );
                      })}
                  </div>
                }
                actions={
                  <div className="container absolute bottom-12 left-0 flex flex-col gap-y-3">
                    {phoneNumber && (
                      <Typo
                        variant={ETypoVariant.BODY_TITLE_16}
                        color={ETypoColor.TEXT}
                        tag={ETypoTag.P}
                      >
                        {t("contact_us")}
                      </Typo>
                    )}
                    <div className="flex w-full items-center justify-between gap-x-12">
                      {phoneNumber && (
                        <a href={`tel:${phoneNumber}`}>
                          <Typo
                            className="font-bold leading-7"
                            variant={ETypoVariant.HEADER_20}
                            color={ETypoColor.TEXT}
                            tag={ETypoTag.P}
                          >
                            {phoneNumber}
                          </Typo>
                        </a>
                      )}

                      <div className="flex items-center gap-x-6">
                        {socialLinks?.map((socialLink) => (
                          <Link
                            key={socialLink?.type}
                            target="_blank"
                            to={socialLink?.url || ""}
                            rel="noreferrer"
                          >
                            <Icon
                              TypeIcon={
                                SOCIAL_DARK_ICONS?.[
                                  socialLink?.type as ESocialLinks
                                ] || FacebookDarkIcon
                              }
                            />
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                }
              >
                <div ref={drawerIconRef}>
                  <IconMenuBar
                    onClick={() => setOpenDrawer(!openDrawer)}
                    className="cursor-pointer"
                  />
                </div>
              </CustomDrawer>
            </div>
          </div>
          {/* <div
            ref={drawerRef}
            className={cn(
              "absolute z-100 w-full overflow-hidden bg-headerBackground px-6 shadow-header-mobile transition-all duration-300 ease-in-out",
              openDrawer ? "max-h-[100vh]" : "max-h-0"
            )}
            style={{ top: `${top}px` }}
          >
            <div className="flex">
              <div className="flex-1">
                {!isEmpty(menus) &&
                  menus.map((menu, index) => (
                    <NavLink
                      key={menu.href + index}
                      to={menu.href}
                      onClick={() => setOpenDrawer(false)}
                      className="flex items-center justify-start border-b border-headerDivider py-3 last-of-type:border-none"
                    >
                      {({ isActive, isPending }) => (
                        <Typo
                          tag={ETypoTag.H3}
                          variant={ETypoVariant.HEADER_14}
                          color={ETypoColor.HEADER_TEXT}
                          className={cn(
                            "text-center transition-all duration-200 hover:text-headerTextHover",
                            isPending || isActive
                              ? "font-bold text-headerTextActive"
                              : ""
                          )}
                        >
                          {menu.title}
                        </Typo>
                      )}
                    </NavLink>
                  ))}
              </div>
              <div ref={drawerIconRef}>
                <IconMenuBar
                  onClick={() => setOpenDrawer(!openDrawer)}
                  className="cursor-pointer"
                />
              </div>

              {isProjectDetailPage && (
                <div className="flex-1">
                  {!isEmpty(projectMenu) &&
                    projectMenu?.map((section) => {
                      const active = currentSection === section.targetId;
                      return (
                        <div
                          key={section.targetId}
                          onClick={() => {
                            scrollToView(section.targetId, SCROLL_OFFSET);
                          }}
                          aria-hidden
                          className="flex items-center justify-start border-b border-headerDivider py-3 last-of-type:border-none"
                        >
                          <Typo
                            tag={ETypoTag.H3}
                            variant={ETypoVariant.HEADER_14}
                            color={ETypoColor.HEADER_TEXT}
                            className={cn(
                              "text-center transition-all duration-200 hover:text-headerTextHover",
                              active ? "font-bold text-headerTextActive" : ""
                            )}
                          >
                            {t(section.label)}
                          </Typo>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div> */}
        </header>
      </>
    );
  }
);

export default HeaderMobileLDP;
