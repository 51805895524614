import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useMemo, useRef, useState } from "react";
import { SwiperOptions } from "swiper/types";

import { Icon } from "~/components/atoms/Icons";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import { SwiperSlide } from "~/components/atoms/Swiper";
import Swiper, { ForwardedSwiper } from "~/components/atoms/Swiper/Swiper";
import Typo from "~/components/atoms/Typo";
import Video from "~/components/atoms/Video";
import ModalMediaGallery from "~/components/organisms/ModalMediaGallery";
import { IProject } from "~/entities/project";
import useMediaQuery from "~/hooks/use-mediaquery";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { EMedia } from "~/utilities/enums/Media";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface MastheadGalleryProps {
  project: IProject;
  onShowPriceList: () => void;
  onShowFlat: () => void;
  className?: string;
  isShowPriceList?: boolean;
  isShowFlat?: boolean;
  isShowColtrols?: boolean;
  isShowProjectName?: boolean;
  size?: string;
  showGradientLayer?: boolean;
}

const MastheadGalleryLDP: React.FC<MastheadGalleryProps> = ({
  project,
  className = "",
  onShowPriceList,
  onShowFlat,
  isShowPriceList = true,
  isShowFlat = true,
  isShowColtrols = true,
  isShowProjectName = true,
  size = "large",
  showGradientLayer = true,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [indexSlides, setIndexSlides] = useState<number>(0);
  const [swiperLoaded, setSwiperLoaded] = useState<boolean>(false);
  const isLargeScreen = useMediaQuery("lg");
  const swiperRef = useRef<ForwardedSwiper | null>(null);

  const { countImage, countVideo, countMedia } = useMemo(() => {
    if (!isArray(project?.medias) || isEmpty(project?.medias))
      return { countImage: 0, countVideo: 0 };
    const countImage = project?.medias?.filter(
      (media) => media.type == EMedia.IMAGE
    ).length;

    const countVideo = project?.medias.filter(
      (medias) => medias.type == EMedia.VIDEO
    ).length;

    const countMedia =
      project?.medias.length < 10
        ? `0${project?.medias.length}`
        : project?.medias.length;

    return { countImage, countVideo, countMedia };
  }, [project?.medias]);

  const options: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    initialSlide: activeIndex,
    preventClicksPropagation: true,
    touchMoveStopPropagation: true,
    setWrapperSize: true,
    autoplay: true,
    observeParents: true,
    observer: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    on: {
      init: () => {
        setSwiperLoaded(true);
      },
      slideChange: (swiper) => {
        setActiveIndex(swiper.activeIndex);
      },
    },
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setIndexSlides(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      <div
        className={cn(
          "max-w-screen relative h-[584px] max-h-screen w-full overflow-hidden py-0 lg:h-full lg:px-0",
          className,
          size === "small"
            ? "h-[420px] lg:h-[420px]"
            : "lg:h-[calc(100dvh_-_50px)]"
        )}
      >
        <Swiper
          className={cn(
            "overflow-initial relative box-border flex h-[584px] w-full items-center justify-center lg:h-full",
            size === "small" ? "h-[420px] lg:h-[420px]" : "lg:h-full",
            { "swiper-initialized": swiperLoaded }
          )}
          defaultValue={activeIndex}
          ref={swiperRef}
          {...options}
        >
          {(project?.photo
            ? [project?.photo, ...(project?.medias || [])]
            : project?.medias || []
          )?.map((media, index) => (
            <SwiperSlide
              key={media.id + index}
              className={cn(
                "h-[584px] w-full cursor-pointer lg:h-[560px]",
                size === "small"
                  ? "h-[420px] lg:h-[420px]"
                  : "lg:h-[calc(100dvh_-_50px)]"
              )}
              onClick={() => {
                handleOpenModal(index);
              }}
              style={{
                visibility: swiperLoaded ? "visible" : "hidden",
              }}
            >
              {showGradientLayer && (
                <div className="absolute z-10 h-full w-full bg-ldp-masthead-gradient" />
              )}

              {media.type === EMedia.IMAGE ? (
                <ImageResponsive
                  className={cn(
                    "h-[584px] w-full lg:h-[560px] lg:object-center",
                    size === "small" ? "h-[420px] lg:h-[420px]" : "lg:h-full"
                  )}
                  imageData={media}
                />
              ) : (
                <Video
                  videoData={media}
                  autoPlay={activeIndex === index}
                  className={cn(
                    "h-[584px] w-full lg:h-[560px]",
                    size === "small" ? "h-[420px] lg:h-[420px] " : "lg:h-full"
                  )}
                  iframeClass={cn(
                    "w-full h-[584px] h-auto lg:h-[560px]",
                    size === "small" ? "h-[420px] lg:h-[420px]" : "lg:h-full"
                  )}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        {isShowProjectName && project?.name && (
          <div className="absolute left-1/2 top-0 z-50 my-12 flex w-fit -translate-x-1/2 transform flex-col items-center justify-center gap-3 lg:my-8">
            <Typo
              tag={ETypoTag.H2}
              variant={ETypoVariant.HEADER_68}
              color={ETypoColor.HEADER_TEXT}
              className="text-center"
            >
              {project?.name}
            </Typo>
            {project?.location?.name && (
              <Typo
                tag={ETypoTag.P}
                variant={ETypoVariant.HEADER_24}
                color={ETypoColor.HEADER_TEXT}
                className="text-center"
              >
                {project?.location?.name}
              </Typo>
            )}
          </div>
        )}
        {!!project?.medias?.length && (
          <div className="absolute left-0 top-0 z-50 h-full w-full">
            {isShowColtrols && (
              <div
                type="button"
                className="ldp relative h-full"
                onClick={() => {
                  handleOpenModal(activeIndex);
                }}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    swiperRef.current?.core?.swiper?.slidePrev();
                  }}
                  className={cn(
                    "absolute left-0 top-1/2 z-50 flex -translate-y-1/2 items-center justify-center rounded-full bg-color p-3 shadow-md transition-transform duration-200 hover:bg-color ldp:left-4",
                    {
                      "border-1 cursor-not-allowed border border-color bg-transparent hover:bg-transparent":
                        activeIndex === 0,
                      "hover:scale-105 active:scale-95": activeIndex !== 0,
                    }
                  )}
                  disabled={activeIndex === 0}
                >
                  <Icon
                    TypeIcon={EIcon.ArrowLeftIcon}
                    height={40}
                    className={
                      activeIndex === 0
                        ? "text-color"
                        : "text-backgroundPageProject"
                    }
                    width={20}
                  />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    swiperRef.current?.core?.swiper?.slideNext();
                  }}
                  className={cn(
                    "absolute right-0 top-1/2  z-50 flex -translate-y-1/2 items-center justify-center rounded-full bg-color p-3 shadow-md transition-transform duration-200 hover:bg-color ldp:right-4",
                    {
                      "border-1 cursor-not-allowed border border-color bg-transparent hover:bg-transparent":
                        activeIndex === project.medias.length - 1,
                      "hover:scale-105 active:scale-95":
                        activeIndex !== project.medias.length - 1,
                    }
                  )}
                  disabled={activeIndex === project.medias.length - 1}
                >
                  <Icon
                    TypeIcon={EIcon.ArrowRightIcon}
                    height={40}
                    width={20}
                    className={
                      activeIndex === project.medias.length - 1
                        ? "text-color"
                        : "text-backgroundPageProject"
                    }
                  />
                </button>
              </div>
            )}
          </div>
        )}

        {countMedia && Number(countMedia) > 0 && (
          <div className="ldp h-fit">
            <div className="absolute bottom-4 z-50 lg:bottom-4">
              <button
                type="button"
                className="w-fit rounded-xl bg-color px-3 py-2 opacity-75"
                onClick={() => handleOpenModal(activeIndex)}
              >
                <Typo
                  tag={ETypoTag.SPAN}
                  variant={ETypoVariant.SUB_TITLE_14}
                  color={ETypoColor.WHITE}
                  className="mr-auto text-backgroundPageProject"
                >
                  {`${(activeIndex + 1).toString().padStart(2, "0")}`}/
                  {countMedia}
                </Typo>
                {/* {countImage > 0 && (
            <dv className="flex items-center gap-x-2 rounded-xl bg-gableGreen50 px-3 py-2 lg:rounded-md lg:p-2">
              <Icon
                TypeIcon={EIcon.ImageIcon}
                className="text-backgroundPageProject lg:h-3 lg:w-3"
              />
              <Typo
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.SUB_TITLE_14}
                color={ETypoColor.WHITE}
                className="text-backgroundPageProject"
              >
                {countImage < 10 ? `0${countImage}` : countImage}
              </Typo>
            </dv>
          )} */}
                {/* {countVideo > 0 && (
            <div className="flex items-center gap-x-2 rounded-xl bg-color bg-opacity-50 px-3 py-2 lg:rounded-md lg:p-2">
              <Icon
                TypeIcon={EIcon.VideoIcon}
                className="text-backgroundPageProject lg:h-3 lg:w-3"
              />
              <Typo
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.SUB_TITLE_14}
                color={ETypoColor.WHITE}
                className="text-backgroundPageProject"
              >
                {countVideo < 10 ? `0${countVideo}` : countVideo}
              </Typo>
            </div>
          )} */}
              </button>
            </div>
          </div>
        )}
        <ModalMediaGallery
          medias={project?.medias}
          closeModal={handleCloseModal}
          isOpen={isOpen}
          indexSlide={indexSlides}
        />

        {/* <MastHeadForm
          project={project}
          onShowFlat={onShowFlat}
          onShowPriceList={onShowPriceList}
          wrapperClass="absolute left-1/2 top-1/2 lg:left-6 lg:w-[calc(100%-50px)] transform -translate-x-1/2 -translate-y-1/2 lg:translate-x-0 bg-gableGreen75 z-10"
        /> */}
      </div>
    </>
  );
};

export default MastheadGalleryLDP;
